<script>
import Layout from "../layouts/main.vue";
// import PageHeader from "@/components/page-header";
import RailAPI from "../../api/RailAPI";

export default {
  name: "",
  components: {
    Layout
    // PageHeader
  },
  data() {
    return {
      title: "Promotion",
      items: [
        {
          text: "Promotions"
        },
        {
          text: "Promotion",
          active: true
        }
      ],
      // 跨域资源，需要正确的代理头才可以正常显示
      urlPrefix: process.env.VUE_APP_BFF_API + "/v1/cms/images",
      promotion: {},
      isFromBanner: false
    };
  },
  created() {
    let id = this.$route.query.id;
    let type = this.$route.query.type;
    console.info(type);
    if (type) {
      this.isFromBanner = true;
      this.getBanner(id);
    } else {
      this.getPromotion(id);
    }
  },
  methods: {
    getPromotion(id) {
      RailAPI.getPromotion(id)
        .then(res => {
          let resPromotion = res.data;
          this.promotion = resPromotion;
        })
        .catch(error => {
          console.log(error);
        });
    },
    getBanner(id) {
      RailAPI.getBanner(id)
        .then(res => {
          let resPromotion = res.data;
          this.promotion = resPromotion;
          console.log(resPromotion);
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
};
</script>

<template>
  <Layout>
    <!-- <PageHeader :title="title" :items="items" /> -->
    <!-- <b-card
        class="bg-soft-primary"
        > -->
    <div>
      <span>
        <!--					<img src="@/assets/images/RailEurope_logo.png" alt height="20" />-->
        <img src="@/assets/images/logo-raileurope.png" alt height="50" />
      </span>
    </div>
    <div class="mt-3 d-flex justify-content-center" v-if="promotion">
      <div class="d-flex flex-column justify-content-center p-4 bg-white">
        <div class="p-4 border-bottom text-center">
          <h4>{{ promotion.title }}</h4>
        </div>
        <div class="mt-3 font-size-17">
          <p>{{ promotion.introduction }}</p>
        </div>
        <div class="mt-1 text-center" v-if="promotion.photo">
          <b-img
            :src="urlPrefix + promotion.photo.url"
            fluid
            alt="Image"
          ></b-img>
        </div>
        <div class="mt-3">
          <p v-html="promotion.description"></p>
        </div>
      </div>
    </div>

    <!-- </b-card> -->
  </Layout>
</template>
